import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { t, t2 } from '@/@core/libs/i18n/utils'
import { $themeConfig } from '@themeConfig'

export default function tableRole() {
  // Use toast
  const toast = useToast()

  const refRoleTable = ref(null)

  const msgList = {
    errorfetch: t2('Error fetching {module} list', { module: t('Role') }),
    code: `${t('Code')}`,
    name: `${t('Name')}`,
    user: `${t('User')}`,
    actions: t('Actions'),
  }

  // Table Handlers
  const tableColumns = [
    { key: 'code', sortable: true, label: msgList.code },
    { key: 'name', sortable: true, label: msgList.name },
    {
      key: 'user', sortable: false, label: msgList.user, class: 'text-center',
    },
    { key: 'actions', label: msgList.actions },
  ]

  const perPage = ref(10)
  const totalRoles = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [2, 5, 10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('name')
  const isSortDirDesc = ref(false)
  const locale = ref('th')

  const dataMeta = computed(() => {
    const localItemsCount = refRoleTable.value ? refRoleTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalRoles.value,
    }
  })

  const refetchData = () => {
    if (refRoleTable.value !== null) {
      refRoleTable.value.refresh()
    }
  }

  watch([currentPage, perPage, searchQuery, locale], () => {
    refetchData()
  })

  const getListRole = (ctx, callback) => {
    const params = {
      q: searchQuery.value,
      perPage: perPage.value,
      page: currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      language: locale.value,
    }
    store
      .dispatch('store-role/getListRole', params)
      .then(response => {
        const { list, total } = response.data
        callback(list)
        totalRoles.value = total
        localStorage.setItem(`${$themeConfig.app.session}-filter-role`, JSON.stringify(params))
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: msgList.errorfetch,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        },
        {
          position: 'bottom-right',
        })
      })
  }

  return {
    getListRole,
    tableColumns,
    perPage,
    currentPage,
    totalRoles,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    locale,
    refRoleTable,

    refetchData,
  }
}
