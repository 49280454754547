import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getDefaultRole(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/get_default_role', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getListRole(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/get_list_role', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getRole(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/get_role', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addRole(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/add_role', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editRole(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/edit_role', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteRole(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/delete_role', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getRoleOptions(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/role_options', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
